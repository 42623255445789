var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-16 sticky top-0 z-40 bg-navbarColor flex items-center justify-between sm:px-16 px-5"},[_c('img',{staticClass:"h-9 object-contain cursor-pointer",attrs:{"src":require("@/assets/Lipalink-Logo-white.png")},on:{"click":function($event){return _vm.goToHome()}}}),(_vm.getUser)?_c('div',{staticClass:"flex text-white items-center gap-2"},[(
        _vm.getUser.role == 'Paybill User' ||
        _vm.getUser.role == 'Company Administrator'
      )?_c('img',{staticClass:"h-8 w-8 rounded-full p-1 bg-white object-contain",attrs:{"src":_vm.getUser.organizationLogo}}):_vm._e(),_c('div',{staticClass:"flex items-center gap-3 cursor-pointer hover:bg-gray-600 relative account-dropdown"},[(
          _vm.getUser.role !== 'Paybill User' &&
          _vm.getUser.role !== 'Company Administrator'
        )?_c('img',{staticClass:"h-4 object-contain",attrs:{"src":require("@/assets/icons/user.png")}}):_vm._e(),_c('p',{staticClass:"text-xs text-center"},[_vm._v(" "+_vm._s(_vm.getUser.organizationName == "" ? "Profile" : _vm.getUser.organizationName)),_c('br')]),_c('img',{staticClass:"h-4 object-contain",attrs:{"src":require("@/assets/icons/angle-small-down.png")}}),_c('div',{staticClass:"dropdown-content mt-24 rounded h-auto text-sm flex flex-col justify-start items-center w-36 gap-0 bg-white shadow text-black"},[_c('div',{staticClass:"cursor-pointer hover:text-white hover:bg-navbarColor w-full p-2",on:{"click":function($event){return _vm.gotoChangePassword()}}},[_vm._v(" Change password ")]),_c('div',{staticClass:"cursor-pointer hover:text-white hover:bg-navbarColor w-full p-2",on:{"click":function($event){return _vm.logOut()}}},[_vm._v(" Log Out ")])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }