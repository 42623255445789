<template>
  <div
    class="h-16 sticky top-0 z-40 bg-navbarColor flex items-center justify-between sm:px-16 px-5"
  >
    <img
      @click="goToHome()"
      class="h-9 object-contain cursor-pointer"
      src="@/assets/Lipalink-Logo-white.png"
    />
    <div v-if="getUser" class="flex text-white items-center gap-2">
      <img
        v-if="
          getUser.role == 'Paybill User' ||
          getUser.role == 'Company Administrator'
        "
        class="h-8 w-8 rounded-full p-1 bg-white object-contain"
        :src="getUser.organizationLogo"
      />
      <div
        class="flex items-center gap-3 cursor-pointer hover:bg-gray-600 relative account-dropdown"
      >
        <img
          v-if="
            getUser.role !== 'Paybill User' &&
            getUser.role !== 'Company Administrator'
          "
          class="h-4 object-contain"
          src="@/assets/icons/user.png"
        />
        <p class="text-xs text-center">
          {{
            getUser.organizationName == ""
              ? "Profile"
              : getUser.organizationName
          }}<br />
        </p>
        <img
          class="h-4 object-contain"
          src="@/assets/icons/angle-small-down.png"
        />
        <div
          class="dropdown-content mt-24 rounded h-auto text-sm flex flex-col justify-start items-center w-36 gap-0 bg-white shadow text-black"
        >
          <!-- <div>
            {{ getUser.fullName }}
            {{ getUser.role }}
          </div> -->
          <div
            class="cursor-pointer hover:text-white hover:bg-navbarColor w-full p-2"
            @click="gotoChangePassword()"
          >
            Change password
          </div>
          <div
            class="cursor-pointer hover:text-white hover:bg-navbarColor w-full p-2"
            @click="logOut()"
          >
            Log Out
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TopBarNav",
  computed: {
    ...mapGetters(["getUser", "getOrganization"]),
  },
  methods: {
    logOut() {
      this.$store.commit("logout");
      this.$router.replace({
        name: "login",
        params: { organization: this.$route.params.organization },
      });
    },
    gotoChangePassword() {
      this.$router.replace({
        name: "changePassword",
        params: { organization: this.$route.params.organization },
      });
    },
    goToHome() {
      this.$router.replace({ name: "dashboard" });
    },
  },
};
</script>

<style scoped>
.dropdown-content {
  position: absolute;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
}
.account-dropdown:hover .dropdown-content {
  max-height: 250px;
  transition: max-height 0.25s ease-in;
}
.dropdown-content div {
  text-decoration: none;
  display: block;
}
</style>
